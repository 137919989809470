import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import About from './pages/About';
import Beef from './pages/Beef';
import Pumpkins from './pages/Pumpkins';
import Contact from './pages/Contact';

export default function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/beef">
          <Beef />
        </Route>
        <Route path="/contact">
          <Contact />
        </Route>
        <Route path="/pumpkins">
          <Pumpkins />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  );
}
