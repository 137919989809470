import React, { Component } from 'react';
// import ProgressiveImage from 'react-progressive-image';
import PumpkinFlyerSection from './PumpkinFlyerSection';
import './style.global.scss';

export default class Pumpkins extends Component {
  render() {
    return (
      <div className="efPumpkins">
        <div className="efPumpkinsSections">
          <h2>2019 Pumpkin Patch Flyer</h2>
          <PumpkinFlyerSection />
        </div>
      </div>
    );
  }
}
