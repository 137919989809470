import React, { Component } from 'react';
import pumpkinPatch from '../../images/pumpkin_patch.jpeg';
import lizaWheelbarrow from '../../images/liza_in_wheelbarrow.jpeg';
import pumpkinSorghum from '../../images/pumpkin_and_sorghum.jpeg';
import williamLizaPumpkins from '../../images/william_liza_pumpkins.jpeg';
import './style.global.scss';

export default class PumpkinFlyerSection extends Component {
  render() {
    return (
      <section>
        <div className="d-flex flex-column align-items-center">
          <div className="flyer">
            <h1 className="text-center m-4 font-rye">U-Pick Pumpkins</h1>
            <h3 className="text-center font-alice">
              October 1 – November 2, 2019
            </h3>
            <h3 className="text-center font-alice mb-5">
              Monday - Saturday: 10am – dusk
            </h3>
            <img src={pumpkinPatch} alt="Pumpkin Patch" />
            <h2 className="text-center mt-5 mb-4 font-fredericka">
              Heirloom Varieties
            </h2>
            <p className="text-center font-alice">
              All of our pumpkins are heirloom varieties ideal for eating and
              for decorating
            </p>
            <div className="border border-white p-4 mt-5">
              <div className="row mt-2 mb-4 pl-4 pr-4">
                <div className="col-5 d-flex align-items-center align-right">
                  <h4 className="font-alice text-right">
                    Make a stunning statement with our gigantic
                  </h4>
                </div>
                <div className="col-7">
                  <h1 className="font-rye">Big Max</h1>
                </div>
              </div>
              <div className="row mb-4 pl-4 pr-4">
                <div className="col-5 d-flex align-items-center align-right">
                  <h4 className="font-alice text-right">
                    Carve a dynamite jack-o-lantern from the classic
                  </h4>
                </div>
                <div className="col-7">
                  <h2 className="font-rye">Connecticut Field</h2>
                </div>
              </div>
              <div className="row mb-4 pl-4 pr-4">
                <div className="col-5 d-flex align-items-center align-right">
                  <h4 className="font-alice text-right">
                    Bake the perfect pie with our
                  </h4>
                </div>
                <div className="col-7">
                  <h2 className="font-rye">Long Island Cheese</h2>
                </div>
              </div>
              <div className="row">
                <h4 className="font-alice ml-5 mr-5">
                  * Prices range from $2-$20, based on size. We accept cash and
                  checks made payable to Earthodox Farm
                </h4>
              </div>
            </div>
            <h2 className="text-center mt-5 mb-5 font-fredericka">
              Wholesome fun for the whole family!
            </h2>
            <div className="row no-gutters justify-content-between image-row">
              <div className="image-item">
                <img
                  src={williamLizaPumpkins}
                  alt="Boy and girl sitting with pumpkins on straw bales"
                />
              </div>
              <div className="image-item">
                <img src={pumpkinSorghum} alt="" />
              </div>
              <div className="image-item">
                <img
                  src={lizaWheelbarrow}
                  alt="Girl in wheelbarrow with pumpkins"
                />
              </div>
            </div>
            <p className="text-center font-alice mt-5">
              Earthodox Farm <br />
              444 Last Chance Rd. Walla Walla
              <br />
              info@earthodoxfarm.com
            </p>
          </div>
        </div>
      </section>
    );
  }
}
