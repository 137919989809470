import React, { Component } from 'react';
import './style.global.scss';

export default class About extends Component {
  render() {
    return (
      <div className="efAbout">
        <div className="efAboutSections">
          <section>
            <h2>About</h2>
          </section>
        </div>
      </div>
    );
  }
}
