import React, { Component } from 'react';
import './style.global.scss';

export default class Beef extends Component {
  render() {
    return (
      <div className="efBeef">
        <div className="efBeefSections">
          <section>
            <h2>Beef</h2>
          </section>
        </div>
      </div>
    );
  }
}
