import React, { Component } from 'react';
// import styles from './style.scss';
import { Link } from 'react-router-dom';
import './style.global.scss';

export default class Navbar extends Component {
  render() {
    return (
      <nav className="navbar navbar-expand-lg navbar-dark bg-primary efNavbar">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            Earthodox Farm
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav">
              {
                // <li>
                //   <Link to="/" className="nav-link">
                //     Home
                //   </Link>
                // </li>
                // <li>
                //   <Link to="/about" className="nav-link" href="#">
                //     About Us
                //   </Link>
                // </li>
                // <li className="nav-item dropdown">
                //   <a
                //     className="nav-link dropdown-toggle"
                //     data-toggle="dropdown"
                //     href="#"
                //     role="button"
                //     aria-haspopup="true"
                //     aria-expanded="false"
                //   >
                //     Our Products
                //   </a>
                //   <div className="dropdown-menu">
                //     <Link to="/beef" className="dropdown-item" href="#">
                //       Highland Beef
                //     </Link>
                //     <Link to="/pumpkins" className="dropdown-item" href="#">
                //       Pumpkins
                //     </Link>
                //   </div>
                // </li>{' '}
                // <li>
                //   <Link to="/contact" className="nav-link" href="#">
                //     Contact Us
                //   </Link>
                // </li>
              }
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
