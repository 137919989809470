import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import './style.global.scss';
import Logo from '../../components/Logo';
import pumpkinPatch from '../../images/pumpkin_patch.jpeg';
import liza from '../../images/liza_in_wheelbarrow.jpeg';
import pumpkinStencil from '../../images/pumpkin_stencil.png';
import pumpkinsClose from '../../images/pumpkin_close_up.jpeg'

export default class Home extends Component {
  render() {
    return (
      <div className="efHome">
        <img
          className="efHomeBackground"
          src={pumpkinPatch}
          alt="Pumpkin Patch"
        />
        <div className="row efHomeSections">
          <div className="col">
            <section className="row">
              <div className="col">
                <Logo />
              </div>
            </section>
            <section className="row pumpkin-patch-info-section align-items-center">
              <div className="col-md-4 col-xl-3 info-col">
                <img
                  className="liza-img img-fluid"
                  src={liza}
                  alt="Girl with pumpkins in wheelbarrow"
                />
              </div>
              <div className="col-md-4 col-xl-6 info-col d-flex flex-column justify-content-center align-items-center">
                <div className="jumbotron pumpkin-patch-info mb-3">
                  <div className="">
                    <h1 className="display-3 font-alice text-center">
                      U-Pick Pumpkin Patch
                    </h1>
                    <p className="lead font-alice welcome-msg">
                      Open Sep 30th - Nov 4th for the 2023 pumpkin season
                    </p>
                    <p className="lead font-alice welcome-msg">
                      10am - dusk Monday through Saturday
                    </p>
                    <p className="lead font-alice text-center">
                      444 Last Chance Rd, Walla Walla
                    </p>
                  </div>
                </div>
                <div className="pumpkin-stencil">
                  <img
                    className="pumpkin-stencil-img img-fluid"
                    src={pumpkinStencil}
                    alt="Pumpkin"
                  />
                </div>
              </div>
              <div className="col-md-4 col-xl-3 info-col">
                <img
                  className="pumpkin-close-img img-fluid"
                  src={pumpkinsClose}
                  alt="Pumpkins"
                />
              </div>
            </section>
            <section className="row bg-primary p-3">
              <div className="footer">
                <div className="d-inline-block">
                  <span>Earthodox Farm</span>
                  <br />
                  <span>444 Last Chance Rd</span>
                  <br />
                  <span>Walla Walla, WA 99362</span>
                  <br />
                  <span>info@earthodoxfarm.com</span>
                </div>
                <div className="d-inline-block">
                  <a
                    href="https://www.facebook.com/earthodoxfarm/"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon={faFacebook} />
                  </a>
                </div>
                <div className="d-inline-block">
                  <span>© 2023 Earthodox Farm</span>
                  <br />
                  <span>All rights reserved.</span>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    );
  }
}
