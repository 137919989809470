import React, { Component } from 'react';
import './style.global.scss';

export default class Contact extends Component {
  render() {
    return (
      <div className="efContact">
        <div className="efContactSections">
          <section>
            <h2>Contact</h2>
          </section>
        </div>
      </div>
    );
  }
}
